import { getAxiosDefault } from 'apiClient/config/apiAxiosConfig';
import { useIsMounted } from 'modules/picasso-ui/form/useIsMounted';
import { useEffect, useState } from 'react';
import { findLink } from './apiUtils';
import { isHavingValue, notNullNotUndefined, orElse } from './objectUtils';

export const useResolved = (promiseFnc, idField, options) => {

  const [items, setItems] = useState(null);
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null)
  const [ready, setReady] = useState(orElse(options?.ready, true))

  const isMounted = useIsMounted()

  //console.debug('useResolved render');

  useEffect(() => {
    if (ready) {
      load({...options});
    }
  }, []);

  useEffect(() => {
    if (ready) {
      return;
    }

    if (options.ready === true) {
      setReady(true)
      load({...options});
    }
  }, [options?.ready]);

  const parseListResponseBody = (data) => {
        //console.debug('useResolved load');
        //try different fields to find the items
        if (data.items) {
          return data;
        } 
        else if (data.content) {
          return data.content
        } 
        else if (data._embedded?.items) {
          return data._embedded.items
        } 
        else if (data._embedded && Object.keys(data._embedded).length === 1) {
          //get first emmbedded list
          return data._embedded[Object.keys(data._embedded)[0]]
        } 
        else {
          if ( Array.isArray( data ) ) {
            return data
          }
          else if (Object.keys(data).length === 0) {
            return []
          } 
          else {
            return data
          }
        }
  }

  const load = (options) => {
    options = options || { silent: false}
    if (options.silent !== true) {
      setLoading(true);
    }

    let requestParams = null;
    if (isHavingValue(options?.size)) {
      requestParams = {
        page: options?.pageNumber || 0,
        size: options?.size
      }
    }

    return promiseFnc(requestParams)
      .then((data) => {
/*         if (!isMounted()) {
          return;
        } */

        if (data.headers && data.request && data.status) { //is axios response
          data = data.data
        }

        setItems(parseListResponseBody(data))
        if(data.page) {
          setPage(data.page)
        }

        if (error) {
          setError(null)
        }
        setLoading(false);
      })
      .catch((e) => {
        if (e) {
          setError(e)
        }
        setLoading(false)
      });
  };

  /**
   * Removes from the items in state
   * @param {*} itemId 
   */
  const remove = (itemId) => {
    setItems(currentValue => {
      let updatedItems = [...currentValue];
      updatedItems = updatedItems.filter((i) => i[idField] !== itemId);
      return updatedItems;
    });
  };

  const refresh = (refreshOptions) => {
    return load({
      ...options,
      ...refreshOptions
    })
  };

  const loadPage = (pageNumber) => {
    return load({
      ...options,
      pageNumber
    })
  }

  /**
   * refreshes only a single item
   */
  const refreshItem = (itemId) => {
    notNullNotUndefined(idField) //must be given in useResolved
    notNullNotUndefined(itemId)

    if (!items) {
      console.error('cannot refresh. items is empty');
    }

    const item = items.find((i) => i[idField] === itemId)
    if (!items) {
      item.error('cannot refresh. item not found');
    }

    try {
      const selfLink = findLink(item, 'self');
      if (!selfLink) {
        item.error('cannot refresh. item selfLink not found');
      }

      const href = selfLink.href 

      getAxiosDefault().get(href).then((response) => {
        return response.data;
      }).then((data)=>{
        setItems(currentValue => {
          if (!data) {
            console.error("error on refreshItem: data is null or empty")
          }
          const item = data[0]
          if (item[idField] !== itemId) {
            console.error("error on refreshItem: itemId not matching")
          }

          let updatedItems = [...currentValue];
          updatedItems = updatedItems.filter((i) => i[idField] !== itemId);
          updatedItems.push(item)
          return updatedItems;
        });
      })
    

    } catch (error) {
      console.log(error)
    }


  }

  return [items, refresh, remove, loading, page, loadPage, error, refreshItem];
};

/**
 * Single item
 * @param {} promiseFnc 
 * @param {*} idField 
 * @returns 
 */
export const useResolvedItem = (promiseFnc, idField, ready) => {

  const [item, setItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingFirstTime, setLoadingFirstTime] = useState(true)
  const [error, setError] = useState()

  ready = orElse(ready, true)

  useEffect(() => {
    if (ready) {
      //console.debug('useResolvedItem load useEffect');
      load();
    }
  }, [ready]);

  const load = () => {

    setLoading(true);

    if (!item) {
      setLoadingFirstTime(true)
    }

    return promiseFnc()
      .then((data) => {
        if (error) {
          setError(null)
        }
        //try different fields to find the item
        if (data.items) {
          setItem(data);
        } 
        else if (data.content && !data.name) {//assume its nested content. double check by checking of there is a name (just guessing that there should be a name in this case)
          setItem(data.content);
        } 
        else if (data.headers && data.request && data.status) { //is axios response
          setItem(data.data);
        }
        else {
          if (Object.keys(data).length === 0) {
            setItem(null);
          } else {
            setItem(data);
          }
        }
        
        if (loadingFirstTime) {
          setLoadingFirstTime(false)
        }
        setLoading(false);
      })
      .catch((e) => {
        if (e) {
          setError(e)
        } else {
          setError({
            type: 'unknown'
          })
        }
        setLoading(false)
        if (loadingFirstTime) {
          setLoadingFirstTime(false)
        }
      });
  };

  const refresh = () => {
    if (ready) {
      return load();
    }
    return Promise.resolve()
  };

  return {item, refresh, loading, loadingFirstTime, error, setItem};
};